import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LDFlagChangeset } from 'launchdarkly-js-client-sdk';
import { assign, mapValues } from 'lodash-es';
import type { IFeatureFlagsState } from '../../store/featureFlags/types.js';
import { initializeFeatureFlags, setFeatureFlagsUser } from '../../store/featureFlags/featureFlags.actions.js';
import { setSelectedDealershipId, setSelectedDealershipIds } from '../../store/slices/auth/index.js';

const initialState: IFeatureFlagsState = {
	isInitialized: false,
	isLoading: false,
	hasError: false,
	flags: {},
};

export const {
	reducer,
	actions: { updateFlags },
} = createSlice({
	name: 'featureFlags',
	initialState,
	reducers: {
		updateFlags: (state, action: PayloadAction<LDFlagChangeset>): void => {
			const updatedFlags = mapValues(action.payload, ({ current }) => current);

			assign(state.flags, updatedFlags);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(initializeFeatureFlags.pending, (state) => {
				state.isInitialized = false;
				state.isLoading = true;
				state.hasError = false;
			})
			.addCase(initializeFeatureFlags.fulfilled, (state, action) => {
				state.flags = action.payload;
				state.isInitialized = true;
				state.isLoading = false;
			})
			.addCase(initializeFeatureFlags.rejected, (state) => {
				state.isLoading = false;
				state.hasError = true;
			})
			.addCase(setFeatureFlagsUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(setFeatureFlagsUser.fulfilled, (state, action) => {
				state.flags = action.payload;
				state.isLoading = false;
				state.hasError = false;
			})
			.addCase(setFeatureFlagsUser.rejected, (state) => {
				state.isLoading = false;
				state.hasError = true;
			})
			.addCase(setSelectedDealershipId, (state) => {
				state.isInitialized = false;
			})
			.addCase(setSelectedDealershipIds, (state) => {
				state.isInitialized = false;
			});
	},
});
