import StackTrace from 'stacktrace-js';
import { isString, omitBy } from 'lodash-es';
import loglevel from 'loglevel';
import { toast } from 'react-toastify';
import { EPlatforms } from '../../proto/common/v1/enums/Platforms.js';
import { isErrorWithMessage, isFetchBaseQueryError, isQueryFulfilledError, isRequestError } from './utils/index.js';

const IS_TOAST_ON_ERROR = process.env['IS_TOAST_ON_ERROR'] === 'true';

const SKIP_HEADERS_IN_LOG = ['authorization', 'cookie', 'x-csrf-token'];

export type IFuseErrorContext = Record<string, any>;

export class Logger {
	loggerInstance = loglevel;
	isInitialized = false;
	private extraData = {};

	init(): void {
		if (this.isInitialized) {
			return;
		}

		this.loggerInstance.enableAll();

		this.isInitialized = true;
		this.setExtra('platform', EPlatforms.Transact);
	}

	setExtra(label: string, value: unknown): void {
		this.extraData[label] = value;
	}

	trace(msg: string, object: Record<string, unknown> = {}): void {
		this.loggerInstance.trace(Logger.buildMessage(msg, object));
	}

	debug(msg: string, object: Record<string, unknown> = {}): void {
		this.loggerInstance.debug(Logger.buildMessage(msg, object));
	}

	log(msg: string, object: Record<string, unknown> = {}): void {
		this.loggerInstance.log(Logger.buildMessage(msg, object));
	}

	info(msg: string, object: Record<string, unknown> = {}): void {
		this.loggerInstance.info(Logger.buildMessage(msg, object));
	}

	warn(msg: string, object: Record<string, unknown> = {}): void {
		this.loggerInstance.warn(Logger.buildMessage(msg, object));
	}

	async error(message: string, errorPayload?: unknown, errorContext?: IFuseErrorContext): Promise<void> {
		if (!message && !errorPayload) {
			await this.reportUndefinedError();

			return;
		}

		let errorMessage: string;
		let errorStack: string;
		let context: Record<string, unknown>;

		if (isString(errorPayload)) {
			errorMessage = errorPayload;
		} else if (isRequestError(errorPayload)) {
			context = this.prepareContextForRequestError(errorPayload);
			errorMessage = this.prepareErrorMessageForRequestError(errorPayload);
		} else if (isQueryFulfilledError(errorPayload)) {
			const queryFulfilledError = errorPayload.error;

			errorMessage = (queryFulfilledError.data as any)?.message;
			errorStack = (queryFulfilledError.data as any)?.stack;
		} else if (isFetchBaseQueryError(errorPayload)) {
			errorMessage = (errorPayload.data as any)?.message;
			errorStack = (errorPayload.data as any)?.stack;
		} else if (isErrorWithMessage(errorPayload)) {
			errorMessage = errorPayload?.message;
		}

		const msg = message || errorMessage;
		const stack = errorStack ?? (await Logger.stringifiedStackFromError(errorPayload['stack'] ? (errorPayload as Error) : new Error()));

		this.loggerInstance.error(Logger.buildMessage(msg, { stack, error: errorMessage, ctx: { ...context, ...errorContext } }));

		if (IS_TOAST_ON_ERROR) {
			toast.error(`${msg}: ${errorMessage}`);
		}
	}

	private async reportUndefinedError(): Promise<void> {
		const stack = await Logger.stringifiedStackFromError(new Error());

		this.loggerInstance.error({ msg: 'Error is undefined', stack });
	}

	private prepareContextForRequestError(errorPayload: unknown): Record<string, unknown> {
		const { body, headers: reqHeaders, method, url, response } = errorPayload['request'];
		const headers = omitBy(reqHeaders, (_, key) => SKIP_HEADERS_IN_LOG.includes(key.toLowerCase()));

		return { body, headers, method, url, response };
	}

	private prepareErrorMessageForRequestError(errorPayload: unknown): string {
		return errorPayload['response']?.message || errorPayload['xhr']?.response?.message || errorPayload['message'];
	}

	private static async stringifiedStackFromError(err: Error): Promise<string> {
		try {
			const stackFrames = await StackTrace.fromError(err);
			const frames = stackFrames.map((stackFrame) => stackFrame.toString());

			return frames.join('\n');
		} catch {
			return err?.stack;
		}
	}

	private static buildMessage(msg: string, object: Record<string, unknown> = {}): string {
		return JSON.stringify({
			msg,
			...object,
		});
	}
}

export const logger = new Logger();
