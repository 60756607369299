import type { PropsWithChildren } from 'react';
import React from 'react';
import { UAParser } from 'ua-parser-js';
import { compare } from 'compare-versions';
import { supportedBrowsers } from '../../guards/BrowserCompatibilityGuard/constants.js';
import { BrowserIncompatibilityError } from './BrowserIncompatibilityError/index.js';

const parser = new UAParser();

export const BrowserCompatibilityGuard = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
	const currentBrowser = parser.getBrowser();

	const isCurrentBrowserSupported = supportedBrowsers.some(
		({ name, version }) =>
			(currentBrowser.is(name) || currentBrowser.is(`Mobile ${name}`) || currentBrowser.is(`${name} Headless`)) &&
			compare(version.toString(), currentBrowser.version, '<=')
	);

	if (!isCurrentBrowserSupported) {
		return <BrowserIncompatibilityError />;
	}

	return <>{children}</>;
};
