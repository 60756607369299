import { EDealStatus } from '../proto/deal/v1/enums/DealStatus.js';

export const ENTRY_ROUTE = 'entry';
export const AUTH_ROUTE = 'auth';
export const MAIN_ROUTE = 'main';
export const DESKING_ROUTE = 'desking';
export const DEALS_ROUTE = 'deals';
export const LEADS_ROUTE = 'leads';
export const INVENTORY_ROUTE = 'inventory';
export const USERS_ROUTE = 'users';
export const DASHBOARD_ROUTE = 'dashboard';
export const CUSTOMIZE_ROUTE = {
	PATH: 'customize',
	QUERIES: {
		SECTION: {
			KEY: 'section',
			PROTECTION_PRODUCTS: 'protectionProducts',
		},
		TYPE: {
			KEY: 'type', // EDealType
		},
		RETURN_DEAL: 'return-deal',
	},
};
export const REVIEW_ROUTE = 'review';
export const DECISION_ROUTE = { PATH: 'decision' };
export const DOCUMENTS_ROUTE = 'documents';
export const DONE_ROUTE = 'done';
export const MENU_ROUTE = { PATH: 'menu' };
export const HELP_ROUTE = 'help';
export const APPLICATION_MANAGEMENT_ROUTE = { PATH: 'applicationManagement' };
export const APPLICATION_ROUTE = {
	PATH: 'application',
};
export const APPLICATION_FORMS_ROUTE = {
	PATH: 'forms',
	QUERIES: {
		CUSTOMERID: {
			KEY: 'customerId',
		},
	},
};

export const APPLICATION_PERSONAL_DETAILS_ROUTE = 'personal-details';
export const APPLICATION_RESIDENCE_ROUTE = 'residence';
export const APPLICATION_EMPLOYMENT_ROUTE = 'employment';
export const APPLICATION_TRADE_INS_ROUTE = 'trade-ins';
export const APPLICATION_INSURANCE_ROUTE = 'insurance';
export const APPLICATION_ADDITIONAL_VEHICLE_INFORMATION_ROUTE = 'additional-vehicle-information';
export const APPLICATION_ALMOST_DONE_ROUTE = 'summary';

export const LOGIN_ROUTE = 'login';
export const GABISIGN_ROUTE = 'gabisign';
export const TEMPLATE_ROUTE = 'template';
export const DEALERSHIP_ROUTE = 'dealership';

export const mappedRoutePaths = {
	customize: CUSTOMIZE_ROUTE.PATH,
	review: REVIEW_ROUTE,
	application: APPLICATION_ROUTE.PATH,
	menu: MENU_ROUTE.PATH,
	documents: DOCUMENTS_ROUTE,
	decision: DECISION_ROUTE.PATH,
};

export const mappedDealStatusPaths = {
	desking: mappedRoutePaths.customize,
	review: mappedRoutePaths.review,
	application: mappedRoutePaths.application,
	menu: mappedRoutePaths.menu,
	decision: mappedRoutePaths.decision,
	documents: mappedRoutePaths.documents,
};

export const mappedPathToDealStatus = {
	[mappedDealStatusPaths.desking]: EDealStatus.Desking,
	[mappedDealStatusPaths.review]: EDealStatus.Review,
	[mappedDealStatusPaths.application]: EDealStatus.Application,
	[mappedDealStatusPaths.menu]: EDealStatus.Menu,
	[mappedDealStatusPaths.decision]: EDealStatus.Decision,
	[mappedDealStatusPaths.documents]: EDealStatus.Documents,
};
