import styled from 'styled-components';
import { doubleSpacing, FuseButton, FuseFlex, FuseImage } from '@fuse/react-ui';

export const SErrorPageWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: ${doubleSpacing};
	padding-top: 3.12rem;
`;
export const SErrorPageRootWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 3.12rem;
`;

export const SErrorPageItemsWrapper = styled(FuseFlex)`
	margin: auto;
	max-width: 26rem;
	text-align: center;
`;

export const SMainImage = styled(FuseImage)`
	min-height: 5rem;
	height: 100%;
	max-height: 20rem;
`;

export const SBackButton = styled(FuseButton)`
	max-width: 12rem;
`;
