import jwtDecode from 'jwt-decode';
import type { Auth0Client } from '@auth0/auth0-spa-js';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { startCase } from 'lodash-es';
import type { IUser } from '../../commonUtils/auth/index.js';
import { APP_METADATA_KEY } from '../../commonUtils/auth/index.js';
import type { EUserPermissionType } from '../../types/UserPermissionType.type.js';
import type { EUserResource } from '../../types/UserResource.type.js';
import { EOrganizationType, eOrganizationTypeFromJSON } from '../../proto/organization/v1/enums/organizationType.js';

export const isTokenExpired = (token: string): boolean => {
	try {
		const tokenData: any = jwtDecode(token);

		if (!tokenData || !tokenData.exp) {
			// Token is invalid or doesn't have an expiration time
			return true;
		}

		// Get the expiration time in seconds
		const expirationTime: number = tokenData.exp;

		// Get the current time in seconds
		const currentTime: number = Math.floor(Date.now() / 1000);

		// Compare the current time with the expiration time
		return currentTime >= expirationTime;
	} catch {
		// An error occurred while decoding the token
		return true;
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fromAuth0ToUser = (token: any): IUser => {
	const decodedToken: any = jwtDecode(token);
	const tokenData = decodedToken[APP_METADATA_KEY] || {};
	const permissions = decodedToken?.permissions || [];
	const orgType = eOrganizationTypeFromJSON(startCase(decodedToken.fuseOrgType));
	const fuseOrgId = orgType === EOrganizationType.Company ? decodedToken.fuseOrgId : parseInt(decodedToken.fuseOrgId);

	return {
		id: decodedToken.sub,
		isMaster: !!decodedToken.isMaster,
		orgType,
		fuseOrgId,
		permissions,
		...tokenData,
		// This has to be after all spreads to avoid overrides of allowed dealership ids
		dealershipIds: decodedToken.dealershipIds,
	};
};

export const buildPermission = (type: EUserPermissionType, name: EUserResource): string => `${type}:${name}`;

export const userHasBuiltPermission = (user: IUser, permission: string): boolean => !!user?.permissions?.includes(permission);

export const userHasPermission = (user: IUser, name: EUserResource, type: EUserPermissionType): boolean =>
	userHasBuiltPermission(user, buildPermission(type, name));

export const initAuth0 = async (): Promise<void> => {
	const auth0: Auth0Client = await createAuth0Client({
		domain: process.env.REACT_APP_AUTH0_DOMAIN,
		clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
		authorizationParams: {
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			redirect_uri: window.location.origin,
		},
		cacheLocation: 'localstorage',
	});

	async function handleRedirectCallback(): Promise<void> {
		await auth0.handleRedirectCallback();

		window.history.replaceState({}, document.title, '/');
	}

	const isRedirectCallback = (): boolean => {
		const query = window.location.search;

		return query.includes('code=') && query.includes('state=');
	};

	const isAuthenticated = await auth0.isAuthenticated();

	if (isAuthenticated) {
		try {
			await auth0.getTokenSilently();
			const user = await auth0.getUser();

			if (!user[APP_METADATA_KEY]) {
				return auth0.logout();
			}
		} catch {
			return auth0.logout();
		}
	} else if (isRedirectCallback()) {
		await handleRedirectCallback();
	}
};
