import { kebabCase } from 'lodash-es';
import { EPasswordlessAuthLinkType, ePasswordlessAuthLinkTypeToJSON } from '../../proto/passwordlessAuth/v1/enums/linkType.js';

export const REMOTE_LOGIN = 'remote-login';
export const REMOTE_ROUTE = 'remote';
export const REMOTE_NOT_FOUND = 'remote-not-found';
export const REMOTE_EXPIRED = 'remote-expired';
export const REMOTE_DISCLOSURES_INVALID = 'remote-disclosures-invalid';

export const AUTHORIZED = 'authorized';

export const SIGN_DOCUMENTS_ROUTE = kebabCase(ePasswordlessAuthLinkTypeToJSON(EPasswordlessAuthLinkType.SignDocuments));
export const SIGN_DISCLOSURES_ROUTE = kebabCase(ePasswordlessAuthLinkTypeToJSON(EPasswordlessAuthLinkType.SignDisclosure));
export const CREDIT_APPLICATION_ROUTE = kebabCase(ePasswordlessAuthLinkTypeToJSON(EPasswordlessAuthLinkType.CreditApplication));

export const SIGN_ROUTE = 'sign';
export const TERMS_AGREEMENT_ROUTE = 'terms-agreement';
export const FORMS_ROUTE = 'forms';
export const CREDIT_APPLICATION_DISCLOSURES_ROUTE = 'disclosures';
export const REMOTE_DONE_ROUTE = 'done';
