import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { logger } from '../../commonUtils/Logger/index.js';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		const error = action?.payload?.data;

		logger.error(error?.payload?.message, error);
	}

	return next(action);
};
