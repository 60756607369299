import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';
import { $enum } from 'ts-enum-util';
import { pendingInvalidationsModule } from '../../store/modules/pendingInvalidations.module.js';
import { fetchBaseQueryWithRetry } from './baseQuery.js';
import { ETagType } from './types.js';

const createApi = buildCreateApi(coreModule(), reactHooksModule(), pendingInvalidationsModule());

export const api = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQueryWithRetry,
	tagTypes: [...$enum(ETagType).getValues()],
	endpoints: () => ({}),
});

export const {
	util: { invalidateTags, immediatelyInvalidateTags, selectInvalidatedBy },
} = api;
