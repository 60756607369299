import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from '../store/api/common.api.js';
import { reducer as protectionProductsReducer } from '../store/slices/protectionProducts/index.js';
import { reducer as deskingReducer } from '../store/reducers/desking/desking.js';
import { reducer as authReducer } from '../store/slices/auth/index.js';
import { reducer as sessionReducer } from '../store/slices/session/index.js';
import { reducer as remoteReducer } from '../store/slices/remote/index.js';
import { reducer as creditApplicationReducer } from '../store/creditApplication/index.js';
import { reducer as dealsDashboardReducer } from '../store/slices/dealsDashboard/index.js';
import { reducer as inventoryFiltersReducer } from '../store/slices/inventory/index.js';
import { reducer as featureFlagsReducer } from '../store/featureFlags/index.js';
import { reducer as leadFiltersReducer } from '../store/slices/leadFilters/index.js';
import { reducer as themeReducer } from '../store/slices/theme/index.js';
import { reducer as signaturesReducer } from '../store/slices/signatures/index.js';
import { reducer as pendingInvalidationsReducer } from '../store/slices/pendingInvalidations/index.js';

const authPersistConfig = {
	key: 'auth',
	storage,
	whitelist: ['selectedDealershipId', 'selectedDealershipIds', 'token'],
};

const themePersistConfig = {
	key: 'theme',
	storage,
};

export const rootReducer = combineReducers({
	[api.reducerPath]: api.reducer,
	protectionProducts: protectionProductsReducer,
	desking: deskingReducer,
	auth: persistReducer(authPersistConfig, authReducer),
	session: sessionReducer,
	remote: remoteReducer,
	creditApplication: creditApplicationReducer,
	dealsDashboard: dealsDashboardReducer,
	inventory: inventoryFiltersReducer,
	featureFlags: featureFlagsReducer,
	leadFilters: leadFiltersReducer,
	theme: persistReducer(themePersistConfig, themeReducer),
	signatures: signaturesReducer,
	pendingInvalidations: pendingInvalidationsReducer,
});
