import { Navigate, Route, Routes } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import React, { Suspense } from 'react';
import type { IFuseToastOptions } from '@fuse/react-ui';
import { OverlaysProvider, ToastsProvider } from '@fuse/react-ui';
import { FuseAuth0Provider } from '../providers/FuseAuth0Provider/FuseAuth0.provider.js';
import { DEALS_ROUTE, ENTRY_ROUTE, LOGIN_ROUTE, MAIN_ROUTE } from '../constants/routes.js';
import { REMOTE_LOGIN, REMOTE_ROUTE } from '../pages/Remote/routes.js';
import { LoadingIndicator } from '../components/LoadingIndicator/index.js';
import { LoginPage } from './client/LoginPage/LoginPage.lazy.js';
import { Main } from './client/Main.lazy.js';
import { PasswordlessAuth } from './client/PasswordlessAuth/PasswordlessAuth.lazy.js';
import { RemoteApp } from './Remote/RemoteApp.lazy.js';

const domain = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const redirectUri = `${window.location.origin}/main`;

const toastOptions: IFuseToastOptions = {
	disableAutoHide: false,
	autoHideDuration: 5000,
	position: 'bottom-start',
	hideIcon: false,
};

export const RootRouter = (): JSX.Element => (
	<ToastsProvider options={toastOptions}>
		<OverlaysProvider>
			<Suspense fallback={<LoadingIndicator />}>
				<Routes>
					<Route path="/" element={<Navigate to={`${MAIN_ROUTE}/${ENTRY_ROUTE}/${DEALS_ROUTE}`} replace />} />

					<Route
						path={`${MAIN_ROUTE}/*`}
						element={
							<Auth0Provider
								domain={domain}
								clientId={clientId}
								authorizationParams={{
									redirect_uri: redirectUri,
									audience,
								}}
								cacheLocation="localstorage"
							>
								<Routes>
									<Route path={`${LOGIN_ROUTE}`} element={<LoginPage />} />

									<Route
										path="*"
										element={
											<FuseAuth0Provider>
												<Main />
											</FuseAuth0Provider>
										}
									/>
								</Routes>
							</Auth0Provider>
						}
					/>

					<Route path={REMOTE_LOGIN} element={<PasswordlessAuth />} />

					<Route path={`${REMOTE_ROUTE}/*`} element={<RemoteApp />} />

					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</Suspense>
		</OverlaysProvider>
	</ToastsProvider>
);
