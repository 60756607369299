import type { PropsWithChildren } from 'react';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme, EThemeName } from '@fuse/react-ui';
import { IS_THEME_TOGGLE_ENABLED } from '@shared/featureflags';
import { GlobalStyles } from '../../styles/globalStyles.js';
import { selectUserSelectedThemeType } from '../../store/selectors/theme.selectors.js';
import { useFeatureFlag } from '../../hooks/useFeatureFlag.js';

export const StyledComponentsProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
	const isThemeToggleEnabled = useFeatureFlag(IS_THEME_TOGGLE_ENABLED);

	const themeType = useSelector(selectUserSelectedThemeType);

	const selectedTheme = themeType === EThemeName.Dark ? darkTheme : lightTheme;

	return (
		<ThemeProvider theme={isThemeToggleEnabled ? selectedTheme : lightTheme}>
			<GlobalStyles />
			{children}
		</ThemeProvider>
	);
};
