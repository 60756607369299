import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { LoadingIndicator } from './components/LoadingIndicator';
import { errorReporter, ErrorReportService } from './services/ErrorReport';
import { StyledComponentsProvider } from './providers/StyledComponentsProvider';
import { BrowserCompatibilityGuard } from './guards/BrowserCompatibilityGuard';
import { persistor, store } from './store/store';
import { router } from './commonUtils/router';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';

const runInitializers = async (): Promise<void> => {
	errorReporter.init();
};

(async (): Promise<void> => {
	await runInitializers();
	const container = document.getElementById('app');
	const root = createRoot(container); // createRoot(container!) if you use TypeScript

	root.render(
		<ErrorBoundary isRoot>
			<Provider store={store}>
				<PersistGate loading={<LoadingIndicator />} persistor={persistor}>
					<StyledComponentsProvider>
						<BrowserCompatibilityGuard>
							<ErrorReportService>
								<RouterProvider router={router} />
							</ErrorReportService>
						</BrowserCompatibilityGuard>
					</StyledComponentsProvider>
				</PersistGate>
			</Provider>
		</ErrorBoundary>
	);
})();
