import type { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { launchDarklyClient } from '../../providers/LaunchDarklyProvider/index.js';
import type { ILaunchDarklyProviderProps } from '../../providers/LaunchDarklyProvider/types.js';
import { selectUserEmail, selectUserName, selectUserSelectedDealershipId } from '../../store/selectors/auth.selectors.js';
import { createAppAsyncThunk } from '../../store/utils.js';
import { updateFlags } from './featureFlags.slice.js';

export const initializeFeatureFlags = createAppAsyncThunk<LDFlagSet, ILaunchDarklyProviderProps>(
	'featureFlags/initialize',
	async ({ overrides }, { getState, dispatch }) => {
		const dealershipId = selectUserSelectedDealershipId(getState());
		const userName = selectUserName(getState());
		const userEmail = selectUserEmail(getState());
		const ldEmail = overrides?.email ?? userEmail;

		await launchDarklyClient.initialize(dealershipId, userName, ldEmail);

		launchDarklyClient.subscribeToChanges((changedFlags) => {
			dispatch(updateFlags(changedFlags));
		});

		return launchDarklyClient.getFlags();
	}
);

export const setFeatureFlagsUser = createAppAsyncThunk<LDFlagSet, ILaunchDarklyProviderProps>('featureFlags/setUser', async ({ overrides }, { getState }) => {
	const dealershipId = selectUserSelectedDealershipId(getState());
	const userName = selectUserName(getState());
	const userEmail = selectUserEmail(getState());
	const ldEmail = overrides?.email ?? userEmail;

	return launchDarklyClient.setUser(dealershipId, userName, ldEmail).getFlags();
});
