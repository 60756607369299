import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { get, keys, values } from 'lodash-es';
import type { EInventoryStockType } from '../../../pages/client/EntryPage/Inventory/types.js';
import { setSelectedDealershipId } from '../../../store/slices/auth/index.js';
import type { FiltersMap, IInventoryState } from './types.js';

const initialState: IInventoryState = {
	filters: {
		filtersMap: null,
		searchQuery: null,
		selectedType: null,
		selectedMake: null,
		selectedModel: null,
		selectedYear: null,
		selectedTrim: null,
	},
	pageCount: 1,
};

export const {
	reducer,
	actions: { setFiltersMap, setSearchQuery, setSelectedType, setSelectedMake, setSelectedModel, setSelectedYear, setSelectedTrim, setPageCount },
} = createSlice({
	name: 'inventory',
	initialState,
	reducers: {
		setFiltersMap: (state, action: PayloadAction<FiltersMap>): void => {
			state.filters.filtersMap = action.payload;
		},

		setSearchQuery: (state, action: PayloadAction<string>): void => {
			state.filters.searchQuery = action.payload;
			state.filters.selectedMake = null;
			state.filters.selectedModel = null;
			state.filters.selectedYear = null;
			state.filters.selectedTrim = null;
			state.pageCount = 1;
		},

		setSelectedType: (state, action: PayloadAction<EInventoryStockType>): void => {
			state.filters.selectedType = action.payload;
			state.filters.selectedMake = null;
			state.filters.selectedModel = null;
			state.filters.selectedYear = null;
			state.filters.selectedTrim = null;
			state.pageCount = 1;

			const makeOptions = keys(get(state.filters.filtersMap, [action.payload]));

			// If there's only one make option available, auto-select it
			if (makeOptions?.length === 1) {
				state.filters.selectedMake = makeOptions?.[0];

				const modelOptions = keys(get(state.filters.filtersMap, [action.payload, makeOptions[0]]));

				// If there's only one model option available, auto-select it
				if (modelOptions?.length === 1) {
					state.filters.selectedModel = modelOptions?.[0].replace('model-', '');

					const yearOptions = keys(get(state.filters.filtersMap, [action.payload, makeOptions[0], modelOptions[0]]));

					// If there's only one year option available, auto-select it
					if (yearOptions?.length === 1) {
						state.filters.selectedYear = parseInt(yearOptions?.[0]?.replace('year-', ''));

						const trimOptions = values(
							get(state.filters.filtersMap, [action.payload, makeOptions[0], `model-${modelOptions[0]}`, `year-${yearOptions[0]}`])
						);

						// If there's only one trim option available, auto-select it
						if (trimOptions?.length === 1) {
							state.filters.selectedTrim = trimOptions?.[0];
						}
					}
				}
			}
		},

		setSelectedMake: (state, action: PayloadAction<string>): void => {
			state.filters.searchQuery = null;
			state.filters.selectedMake = action.payload;
			state.filters.selectedModel = null;
			state.filters.selectedYear = null;
			state.filters.selectedTrim = null;
			state.pageCount = 1;

			const modelOptions = keys(get(state.filters.filtersMap, [state.filters.selectedType, action.payload]));

			// If there's only one model option available, auto-select it
			if (modelOptions?.length === 1) {
				state.filters.selectedModel = modelOptions?.[0].replace('model-', '');

				const yearOptions = keys(get(state.filters.filtersMap, [state.filters.selectedType, action.payload, modelOptions[0]]));

				// If there's only one year option available, auto-select it
				if (yearOptions?.length === 1) {
					state.filters.selectedYear = parseInt(yearOptions?.[0]?.replace('year-', ''));

					const trimOptions = values(
						get(state.filters.filtersMap, [state.filters.selectedType, action.payload, `model-${modelOptions[0]}`, `year-${yearOptions[0]}`])
					);

					// If there's only one trim option available, auto-select it
					if (trimOptions?.length === 1) {
						state.filters.selectedTrim = trimOptions?.[0];
					}
				}
			}
		},

		setSelectedModel: (state, action: PayloadAction<string>): void => {
			state.filters.selectedModel = action.payload;
			state.filters.selectedYear = null;
			state.filters.selectedTrim = null;
			state.pageCount = 1;

			const yearOptions = keys(get(state.filters.filtersMap, [state.filters.selectedType, state.filters.selectedMake, action.payload]));

			// If there's only one year option available, auto-select it
			if (yearOptions?.length === 1) {
				state.filters.selectedYear = parseInt(yearOptions?.[0]?.replace('year-', ''));

				const trimOptions = values(
					get(state.filters.filtersMap, [state.filters.selectedType, state.filters.selectedMake, action.payload, `year-${yearOptions[0]}`])
				);

				// If there's only one trim option available, auto-select it
				if (trimOptions?.length === 1) {
					state.filters.selectedTrim = trimOptions?.[0];
				}
			}
		},

		setSelectedYear: (state, action: PayloadAction<number>): void => {
			state.filters.selectedYear = action.payload;
			state.filters.selectedTrim = null;
			state.pageCount = 1;

			const trimOptions = values(
				get(state.filters.filtersMap, [
					state.filters.selectedType,
					state.filters.selectedMake,
					`model-${state.filters.selectedModel}`,
					`year-${action.payload}`,
				])
			);

			// If there's only one trim option available, auto-select it
			if (trimOptions?.length === 1) {
				state.filters.selectedTrim = trimOptions?.[0];
			}
		},

		setSelectedTrim: (state, action: PayloadAction<string>): void => {
			state.filters.selectedTrim = action.payload;
			state.pageCount = 1;
		},

		setPageCount: (state, action: PayloadAction<number>): void => {
			state.pageCount = action.payload;
		},
	},
	extraReducers: {
		[setSelectedDealershipId.type]: (state): void => {
			state.filters.filtersMap = null;
			state.filters.searchQuery = null;
			state.filters.selectedType = null;
			state.filters.selectedMake = null;
			state.filters.selectedModel = null;
			state.filters.selectedYear = null;
			state.filters.selectedTrim = null;
		},
	},
});
