export const supportedBrowsers: { logoUrl: string; name: string; version: number }[] = [
	{
		logoUrl: 'images/browsers/chrome.png',
		name: 'Chrome',
		version: 100,
	},
	{
		logoUrl: 'images/browsers/safari.png',
		name: 'Safari',
		version: 15.5,
	},
	{
		logoUrl: 'images/browsers/edge.png',
		name: 'Edge',
		version: 100,
	},
	{
		logoUrl: 'images/browsers/opera.png',
		name: 'Opera',
		version: 86,
	},
	{
		logoUrl: 'images/browsers/firefox.png',
		name: 'Firefox',
		version: 105,
	},
];
