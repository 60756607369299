import styled from 'styled-components';
import { doubleSpacing, FuseButton, FuseFlex, FuseImage } from '@fuse/react-ui';

export const SAccessDeniedPageWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: ${doubleSpacing};
`;

export const SAccessDeniedItemsWrapper = styled(FuseFlex)`
	margin: auto;
	max-width: 25rem;
	text-align: center;
`;

export const SBackButton = styled(FuseButton)`
	max-width: 18rem;
`;

export const SMainImage = styled(FuseImage)`
	min-height: 5rem;
	max-height: 15rem;
`;

export const SFuseLogo = styled(FuseImage)`
	margin: ${doubleSpacing};
`;
