import type { JSX } from 'react';
import React from 'react';
import { doubleSpacing, FuseFlex, FuseImage, FuseLinkButton, FuseTypography, useAppTheme } from '@fuse/react-ui';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MAIN_ROUTE } from '../../constants/routes';
import type { FallbackRender } from '../../services/ErrorReport';
import { SBackButton, SErrorPageItemsWrapper, SErrorPageWrapper, SMainImage } from './ErrorBoundary.styles';

const toastDefaultDuration = parseInt(process.env.TOAST_DEFAULT_DURATION, 10) ?? 4000;

// eslint-disable-next-line react/prop-types
export const ErrorPage: FallbackRender = ({ resetError }): JSX.Element => {
	const theme = useAppTheme();

	const navigate = useNavigate();

	const handleEmail = (): void => {
		window.location.href = 'mailto:support@fuseautotech.com';
	};
	const handleCTA = (): void => {
		resetError();
		navigate(`/${MAIN_ROUTE}`);
	};

	return (
		<>
			<SErrorPageWrapper>
				<SErrorPageItemsWrapper height="100%" direction="column" justifyContent="space-between" alignItems="center">
					<FuseImage src="images/fuseLogo.svg" alt="Fuse Logo" height="4rem" />
					<SMainImage src="images/car-and-woman.svg" alt="Woman examining a car" />
					<FuseFlex direction="column" gap={doubleSpacing({ theme })} alignItems="center">
						<FuseTypography variant="h2">Whoops, something went wrong.</FuseTypography>
						<FuseTypography variant="b1" color="secondary">
							Please either refresh the page or return home to try again. If the issue continues, please contact support.
						</FuseTypography>
						<FuseLinkButton text="support@fuseautotech.com" data-test-id="contact-support-btn" onClick={handleEmail} />
						<SBackButton text="GO HOME" data-test-id="go-home-btn" variant="filled" onClick={handleCTA} />
					</FuseFlex>
					<FuseTypography variant="b2" color="secondary">
						© Copyrights Fuse autotech | All Rights Reserved
					</FuseTypography>
				</SErrorPageItemsWrapper>
			</SErrorPageWrapper>

			<ToastContainer autoClose={toastDefaultDuration} />
		</>
	);
};
