import type { Action, Dispatch } from 'redux';
import { logger } from '../../commonUtils/Logger/index.js';

export const crashReporterMiddleware =
	() =>
	(dispatch: Dispatch) =>
	(action: Action): Action => {
		try {
			return dispatch(action);
		} catch (err) {
			logger.error('crashReporterMiddleware failed', err);
		}

		return null;
	};
