import { FuseFlex, primaryColorDefault } from '@fuse/react-ui';
import styled from 'styled-components';

export const SWrapper = styled(FuseFlex)`
	padding: 3rem 1rem;
`;

export const SHeadersWrapper = styled(FuseFlex)`
	text-align: center;
`;

export const SLink = styled.a`
	color: ${primaryColorDefault};
`;
