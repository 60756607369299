import { configureStore } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { persistStore } from 'redux-persist';
import { api } from '../store/api/common.api.js';
import { crashReporterMiddleware, rtkQueryErrorLogger } from '../store/middlewares/index.js';
import { errorReporter } from '../services/ErrorReport/index.js';
import { rootReducer } from './reducers.js';
import { pendingInvalidationsHandler } from './middlewares/pendingInvalidationsHandler.js';

const errorReportEnhancer = errorReporter.getReduxEnhancer();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const createStore = () =>
	configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({ serializableCheck: false }) // TODO need to enable serializable check after fixing some bad actions that use unserializable objects
				.prepend(pendingInvalidationsHandler)
				.concat(api.middleware)
				.concat(crashReporterMiddleware)
				.concat(LogRocket.reduxMiddleware())
				.concat(rtkQueryErrorLogger),
		devTools: true,
		enhancers: [errorReportEnhancer],
	});
export const store = createStore();

export const persistor = persistStore(store);
