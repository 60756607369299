import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { assign, isNil, merge } from 'lodash-es';
import type { IFilter } from '../../../types/Filter.type.js';
import { EFilterName, filterDefinitions } from '../../../types/Filter.type.js';
import type { FilterValueOptions, IDealsDashboardState, ITableState } from '../../../store/slices/dealsDashboard/types.js';
import { setSelectedDealershipIds } from '../../../store/slices/auth/index.js';

const initialState: IDealsDashboardState = {
	availableFilterNames: [EFilterName.Status, EFilterName.DealType, EFilterName.DealOwner, EFilterName.Manager],
	selectedFilters: [{ name: null, type: null, value: [] }],
	filterValueOptions: null,
	searchQuery: null,
	shouldReloadDeals: false,
	tables: [],
};

export const {
	reducer,
	actions: {
		initFilters,
		initTables,
		addFilter,
		setFilterName,
		setFilterValue,
		setSearchQuery,
		setTableParams,
		removeFilter,
		resetOffsetInAllTables,
		setShouldReloadData,
	},
} = createSlice({
	name: 'dealsDashboard',
	initialState,
	reducers: {
		initFilters: (state, action: PayloadAction<{ filterValueOptions: FilterValueOptions }>): void => {
			const { filterValueOptions } = action.payload;

			state.filterValueOptions = filterValueOptions;
		},

		initTables: (state, action: PayloadAction<{ tables: ITableState[] }>) => {
			state.tables = action.payload.tables;
		},

		addFilter: (state, action: PayloadAction<{ name?: IFilter['name'] }>): void => {
			const { name } = action.payload;

			if (name) {
				const { type, defaultValue } = filterDefinitions[name];

				state.selectedFilters.push({ name, type, value: defaultValue });
				state.availableFilterNames = state.availableFilterNames.filter((filterName) => filterName !== name);
			} else {
				state.selectedFilters.push({ name: null, type: null, value: null });
			}
		},

		setFilterName: (state, action: PayloadAction<{ name: IFilter['name']; index: number }>): void => {
			const { name, index } = action.payload;

			const oldName = state.selectedFilters?.[index]?.name;
			const { type, defaultValue } = filterDefinitions[name];

			state.availableFilterNames = state.availableFilterNames.filter((filterName) => filterName !== name);
			state.selectedFilters[index] = { name, type, value: defaultValue };

			// If we replaced a previous filter type, return it to the available types
			if (oldName) {
				state.availableFilterNames.push(oldName);
			}
		},

		setFilterValue: (state, action: PayloadAction<{ value: IFilter['value']; index: number }>): void => {
			const { value, index } = action.payload;

			state.selectedFilters[index].value = value;

			// If the filters change, reset the offset for every paginated table
			state.tables.forEach((table) => {
				if (!isNil(table.params?.offset)) {
					table.params.offset = 0;
				}
			});
		},

		setSearchQuery: (state, action: PayloadAction<string>): void => {
			state.searchQuery = action.payload;

			// If the search query changes, reset the offset for every paginated table
			state.tables.forEach((table) => {
				if (!isNil(table.params?.offset)) {
					table.params.offset = 0;
				}
			});
		},

		setTableParams: (state, action: PayloadAction<{ params: ITableState['params']; index: number }>): void => {
			const { params, index } = action.payload;

			merge(state.tables[index].params, params);
		},
		setShouldReloadData: (state, action: PayloadAction<boolean>): void => {
			state.shouldReloadDeals = action.payload;
		},

		removeFilter: (state, action: PayloadAction<{ index: number }>): void => {
			const { index } = action.payload;

			const { name: removedFilterName } = state.selectedFilters[index];

			if (!state.availableFilterNames.includes(removedFilterName) && removedFilterName) {
				state.availableFilterNames.push(removedFilterName);
			}

			if (state.selectedFilters.length === 1) {
				state.selectedFilters = initialState.selectedFilters;
			} else {
				state.selectedFilters.splice(index, 1);
			}
		},
		resetOffsetInAllTables: (state): void => {
			// Reset the offset for every paginated table
			state.tables.forEach((table) => {
				if (!isNil(table.params?.offset) && table?.params?.offset !== 0) {
					table.params.offset = 0;
				}
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setSelectedDealershipIds, (state) => {
			assign(state, initialState);
		});
	},
});
