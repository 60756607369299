import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ISessionState } from '../../../store/slices/session/types.js';

const initialState: ISessionState = {
	url: '',
	id: '',
};

export const {
	reducer,
	actions: { setSessionURL, setSessionId },
} = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setSessionURL: (state, action: PayloadAction<string>): void => {
			state.url = action.payload;
		},
		setSessionId: (state, action: PayloadAction<string>): void => {
			state.id = action.payload;
		},
	},
});
