import { createGlobalStyle } from 'styled-components';
import { fontFamily, textColorDefault } from '@fuse/react-ui';

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    display: flex;

    body {
	  margin: 0;
      padding: 0;
      border: 0;
      width: 100%;
      height: 100%;
      overscroll-behavior-y: none;
	  font-family: ${fontFamily};
	  color: ${textColorDefault}
    }
  }

  // TODO replace this media queries with ones that fit our breakpoints definition
  @media screen and (max-width: 1366px) {
    html {
      font-size: 16px;
    }
  }

  @media screen and (min-width: 1366px) and (max-width: 1919px){
    html {
      font-size: 16px;
    }
  }


  @media screen and (min-width: 1920px) {
    html {
      font-size: 16px;
    }
  }

  #app {
    display: flex;
	height: 100%;
  }

  a {
    text-decoration: unset;
    color: unset;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
  }
  
  ul {
    margin: 0;
    list-style: none;
  }

  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }

  input, input:focus {
    outline: none;
  }

  * {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  @media print {
	// hide scrollber on print
	.os-scrollbar {
	  display: none;
	}
  }
  
`;
