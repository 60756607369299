import { ToastContainer } from 'react-toastify';
import type { JSX, PropsWithChildren } from 'react';
import React from 'react';
import { logger } from '../../commonUtils/Logger';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary as ErrorReporterBoundary } from '../../services/ErrorReport';
import { ErrorPageRoot } from './ErrorPageRoot';
import { ErrorPage } from './ErrorPage';

const toastDefaultDuration = parseInt(process.env.TOAST_DEFAULT_DURATION, 10) ?? 4000;

export const ErrorBoundary = ({ children, isRoot = false }: PropsWithChildren<{ isRoot?: boolean }>): JSX.Element => {
	const handleError = (error: Error): void => {
		logger.error(error.message, error);
	};

	return (
		<ErrorReporterBoundary onError={handleError} fallback={isRoot ? ErrorPage : ErrorPageRoot}>
			{children}
			{!isRoot && <ToastContainer autoClose={toastDefaultDuration} />}
		</ErrorReporterBoundary>
	);
};
