import type { Api, BaseQueryFn, EndpointDefinition, EndpointDefinitions, Module, TagDescription } from '@reduxjs/toolkit/query';
import { assign, isFunction, isNil } from 'lodash-es';
import type { ActionCreatorWithPayload, PrepareAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import { logger } from '../../commonUtils/Logger/index.js';
import { addPendingInvalidation } from '../../store/slices/pendingInvalidations/index.js';
import { hasPendingQueries } from '../api/utils.js';
import type { ETagType } from '../api/types.js';
import { store } from '../store.js';

const pendingInvalidationsModuleName = Symbol('pendingInvalidationsModule');
type PendingInvalidationsModule = typeof pendingInvalidationsModuleName;

declare module '@reduxjs/toolkit/query' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/naming-convention
	export interface ApiModules<BaseQuery extends BaseQueryFn, Definitions extends EndpointDefinitions, ReducerPath extends string, TagTypes extends string> {
		[pendingInvalidationsModuleName]: {
			util: {
				immediatelyInvalidateTags: ActionCreatorWithPayload<TagDescription<ETagType>[]>;
			};
		};
	}
}

export const pendingInvalidationsModule = (): Module<PendingInvalidationsModule> => ({
	name: pendingInvalidationsModuleName,
	init: (
		api
	): {
		injectEndpoint(endpointName: string, definition: EndpointDefinition<any, any, any, any>): void;
	} => {
		const anyApi = api as any as Api<BaseQueryFn, EndpointDefinitions, string, ETagType>;

		const { invalidateTags: immediatelyInvalidateTags, selectInvalidatedBy } = anyApi.util;

		// Override the `invalidateTags` action to push pending invalidations if needed
		const invalidateTags = createAction<PrepareAction<TagDescription<ETagType>[]>>(immediatelyInvalidateTags.type, (tags: TagDescription<ETagType>[]) => {
			const state = store.getState() as any;
			const queriesToInvalidate = selectInvalidatedBy(state, tags);

			// If the tags that we're trying to invalidate have pending queries,
			// we need to wait for them to finish before we can invalidate the tags
			if (hasPendingQueries(state, queriesToInvalidate)) {
				store.dispatch(
					addPendingInvalidation({
						tags,
						endpoints: queriesToInvalidate.map(({ endpointName, originalArgs }) => ({ endpointName, args: originalArgs })),
					})
				);

				return { payload: [], meta: 'Pushed to pending invalidations' };
			}

			return immediatelyInvalidateTags(tags);
		});

		assign(api.util, { invalidateTags, immediatelyInvalidateTags });

		return {
			injectEndpoint(endpoint, definition): void {
				if (!isNil(definition.invalidatesTags)) {
					const endpointInvalidatesTags = definition.invalidatesTags;

					definition.invalidatesTags = (response, error, request, meta): readonly TagDescription<ETagType>[] => {
						let tagsToInvalidate: readonly TagDescription<ETagType>[];

						if (isFunction(endpointInvalidatesTags)) {
							tagsToInvalidate = endpointInvalidatesTags?.(response, error, request, meta) ?? [];
						} else {
							tagsToInvalidate = endpointInvalidatesTags ?? [];
						}

						const state = store.getState() as any;
						const queriesToInvalidate = selectInvalidatedBy(state, tagsToInvalidate);

						// If the tags that we're trying to invalidate have pending queries,
						// we need to wait for them to finish before we can invalidate the tags
						if (hasPendingQueries(state, queriesToInvalidate)) {
							logger.debug('Pushing pending invalidations...', { queriesToInvalidate });

							store.dispatch(
								addPendingInvalidation({
									tags: tagsToInvalidate as TagDescription<ETagType>[],
									endpoints: queriesToInvalidate.map(({ endpointName, originalArgs }) => ({ endpointName, args: originalArgs })),
								})
							);

							return [];
						}

						return tagsToInvalidate;
					};
				}
			},
		};
	},
});
