import { api } from '../../../store/api/common.api.js';
import type { IDealership } from '../../../proto/dealership/v1/messages/Dealership.js';
import { EHttpMethod, ETagType } from '../types.js';
import type { IFetchAllDealershipsResponse, IFetchDealershipsFilters, IFetchDealershipsResponse } from './types.js';
import { generateTags, generateTagsForIds } from '../utils.js';

const dealershipsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		fetchDealerships: builder.query<IFetchDealershipsResponse, IFetchDealershipsFilters>({
			query: ({ dealershipIds }) => ({ method: EHttpMethod.Get, url: '/dealership', params: { dealershipIds } }),
			providesTags: generateTags((response) => generateTagsForIds(response?.dealerships, ETagType.Dealership)),
		}),

		fetchAllDealerships: builder.query<IFetchAllDealershipsResponse, void>({
			query: () => ({ method: EHttpMethod.Get, url: '/dealership/all' }),
			providesTags: generateTags((response) => generateTagsForIds(response?.data, ETagType.Dealership)),
		}),

		fetchDealership: builder.query<IDealership, IDealership['id']>({
			query: (id) => ({ method: EHttpMethod.Get, url: `/dealership/${id}` }),
			providesTags: generateTags((response) => [{ type: ETagType.Dealership, id: response?.id }]),
		}),
	}),
});

export const { useFetchDealershipsQuery, useLazyFetchDealershipQuery, useFetchAllDealershipsQuery, useLazyFetchAllDealershipsQuery, useFetchDealershipQuery } =
	dealershipsApi;
