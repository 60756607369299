import type { BaseQueryFn, FetchBaseQueryError, QueryDefinition, TagDescription } from '@reduxjs/toolkit/query';
import { defaultSerializeQueryArgs, QueryStatus } from '@reduxjs/toolkit/query';
import { isDate, isEqual, isNil, omit, values } from 'lodash-es';
import type { IDeal } from '../../proto/deal/v1/messages/Deal.js';
import { type IStoreState } from '../types.js';
import type { IPagination, ITag } from './types.js';
import { ETagId, ETagType } from './types.js';

export function generateDealIdTag(type: ETagType, dealId: IDeal['id']): ITag {
	return { type, id: `DealId-${dealId}` };
}

export const generateTagsForIds = <T>(
	resultsWithIds: T[],
	tagType: ETagType,
	idOptions?: {
		getId?: (obj: T) => string | number;
		dealId?: IDeal['id'];
	}
): ITag[] => {
	const { getId = (obj): string | number => (obj as any)?.id, dealId } = idOptions ?? {};
	const commonTags: ITag[] = [{ type: tagType, id: ETagId.All }];

	if (!isNil(dealId)) {
		commonTags.push(generateDealIdTag(tagType, dealId));
	}

	return resultsWithIds ? [...commonTags, ...resultsWithIds.map((object) => ({ type: tagType, id: getId(object) }))] : commonTags;
};

export const generateTags =
	<Response, Error, Arg, Meta>(
		onRequestSuccess: (response: Response, error: Error, arg: Arg, meta: Meta) => TagDescription<ETagType>[],
		isEmptyTagsOnError = true
	) =>
	(response: Response, error: Error, arg: Arg, meta: Meta): TagDescription<ETagType>[] => {
		if ((error as FetchBaseQueryError)?.status === 401) {
			return [ETagType.Unauthorized];
		}

		if (error && isEmptyTagsOnError) {
			return [];
		}

		return onRequestSuccess(response, error, arg, meta);
	};

export const addPaginationData = <Response extends IPagination, Request extends IPagination>(
	key: keyof Response
): Partial<Omit<QueryDefinition<Request, BaseQueryFn, string, Response, string>, 'type'>> => {
	return {
		serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) =>
			// Omit the `offset` and `limit` args from the cache key
			// This allows pagination to work correctly, as consequent pages will be merged into the same cache entry
			defaultSerializeQueryArgs({
				endpointName,
				queryArgs: omit(queryArgs, ['offset', 'limit']),
				endpointDefinition,
			}),
		merge: (currentCache, newResponse): void => {
			if (newResponse.offset === 0) {
				// When the first page is fetched, replace the existing cache entry instead of merging it
				currentCache[key] = newResponse[key];
			} else if (currentCache.offset !== newResponse.offset) {
				// When a new page is fetched, merge it into the existing cache entry instead of replacing it
				(currentCache[key] as any).push(...(newResponse[key] as any));
			}

			currentCache.total = newResponse.total;
			currentCache.offset = newResponse.offset;
			currentCache.limit = newResponse.limit;
		},
		forceRefetch: ({ currentArg, previousArg }) =>
			// Re-fetch the data if the `offset` or `limit` args change
			!previousArg || currentArg.offset !== previousArg.offset || currentArg.limit !== previousArg.limit,
	};
};

/**
 * Receives the current store state and a list of queries to check and returns whether any of the queries are pending.
 * If no `args` are provided for a query, it will check if any query with the same `endpointName` is pending.
 * @param state
 * @param queries
 */
export const hasPendingQueries = (state: IStoreState, queries: { endpointName: string; originalArgs?: unknown }[]): boolean => {
	return values(state.api.queries)
		.filter(
			(query) =>
				!!queries.find(
					(queryToCheck) =>
						isEqual(queryToCheck?.endpointName, query?.endpointName) &&
						(isNil(queryToCheck.originalArgs) || isEqual(queryToCheck?.originalArgs, query?.originalArgs))
				)
		)
		.some((query) => query?.status === QueryStatus.pending);
};

export const convertObjectToURLSearchParams = (obj: Record<string, unknown>): URLSearchParams => {
	const params = new URLSearchParams();

	// Helper function to handle array/object elements
	const pushToParams = (path: string, val: any): void => {
		if (Array.isArray(val)) {
			val.forEach((v, i) => pushToParams(`${path}[${i}]`, v));
		} else if (isDate(val)) {
			params.append(path, val.toISOString());
		} else if (typeof val === 'object' && val !== null) {
			Object.entries(val).forEach(([key, v]) => pushToParams(`${path}[${key}]`, v));
		} else {
			params.append(path, val);
		}
	};

	// Start recursion
	Object.entries(obj).forEach(([path, val]) => pushToParams(path, val));

	return params;
};

window['convertObjectToURLSearchParams'] = convertObjectToURLSearchParams;
