import styled from 'styled-components';
import { backgroundColorDefault, EThemeName } from '@fuse/react-ui';
import type { Property } from 'csstype';
import { PulseLoader } from 'react-spinners';

export const SWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: ${({ theme }): Property.BackgroundColor => (theme.colors ? backgroundColorDefault({ theme }) : 'white')};
`;

export const SLoader = styled(PulseLoader).attrs(() => ({
	size: 10,
	color: '#4a37ff',
}))``;

export const SLogo = styled.img.attrs(({ theme }) => ({
	src: !!theme?.colors && theme?.name === EThemeName.Dark ? 'images/fuseLogoDark.svg' : 'images/fuseLogo.svg',
}))`
	height: 4rem;
`;
