import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Selector } from '../../types.js';
import type { IPendingInvalidation, IPendingInvalidationsState } from './types.js';

const initialState: IPendingInvalidationsState = [];

export const {
	reducer,
	actions: { addPendingInvalidation, setPendingInvalidations },
} = createSlice({
	name: 'pendingInvalidations',
	initialState,
	reducers: {
		addPendingInvalidation: (state, action: PayloadAction<IPendingInvalidation>): void => {
			state.push(action.payload);
		},

		setPendingInvalidations: (state, action: PayloadAction<IPendingInvalidation[]>) => action.payload,
	},
});

export const selectPendingInvalidations: Selector<IPendingInvalidationsState> = (state) => state.pendingInvalidations;
